import React from 'react';
import './Experience.css';
import { ProgressBar, Exp } from './ProgressBar';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap-grid.min.css'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I Have</h5>
      <h2>My Skills</h2>
      <Container>
        <Row className="align-items-top">
        
          <Col sm={5} id="professional">
            <h3><b>Professional Experience</b></h3>
            <Exp />
          </Col>
          <Col sm={5} id="skills" >
            <h3>Skills</h3>
            <ProgressBar />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Experience;