const skills = [{
    name: "Python",
    value: 90,
},
{
    name: "Djano",
    value: 85,
},
{
    name: "Javascript",
    value: 75,
},
{
    name: "React",
    value: 60,
},
{
    name: "Jquery",
    value: 85,
},
];

const experience = [{
    name: "Branch Management",
    
},
{
    name: "Operation Management",
  
},
{
    name: "Customer Relationship Management",

},
{
    name: "Microsoft Office",
    
},
{
    name: "Management of Information System"
   
},
];



export { skills, experience  }
