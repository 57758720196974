import React from 'react'
import './Header.css';
import CTA from './CTA';
import Me from "../../assets/myHalf.png";
import HeaderSocial from './HeaderSocial';
function Header() {
  return (
    <header>
      <div className="container header__container">
      <h5>Hello I'm</h5>
      <h1 >Basanta shrestha</h1>
      <h5 className="text-light">UI/UX developer</h5>
      <CTA />
      <HeaderSocial/>
      <div className='me'>
        <img src={Me} alt='me'/>
      </div>
      <a href='#contact' className='scroll__down'>Scroll Down</a>
      </div>
    


    </header>
  );
}

export default Header