import React from 'react';
import './Portfolio.css';
import VideoBlock from './youTube';

const Portfolio = () => {
  return (
    <section id='portfolio'>
      test
      <VideoBlock/>
    </section>
  )
}

export default Portfolio