import React from 'react';
import {BsFillPatchCheckFill} from 'react-icons/bs'


import { skills, experience } from '../../components/ContentManagement';
import {Container, Row, Col} from 'react-bootstrap'
//import 'bootstrap/dist/css/bootstrap-grid.css'

export const ProgressBar = () => {
  return (
          <div >
            {skills.map((data, i) => {
              return (
                <div key={i}>
                  <Row>
                  <Col className='col-sm-3'>
                    {data.name}
                  </Col>
                  <Col className='col-sm-9'style={{ paddingTop: '1.2rem' }}>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{
                        width: `${data.value}%`,
                      }}
                    >
                      <div className="progress-value">{data.value}%</div>
                    </div>
                  </div>
                  </Col>





                  </Row>
                  
                </div>
              );
            })}
          </div>

      
          );
};




export const Exp = () => {
  return (
    <div>
      {experience.map((data,i) =>{
        return(
          <div key={i}>
            
              <Row style={{ paddingTop: '1.2rem' }}>
              <Col className='col-sm-1'>
              <BsFillPatchCheckFill/>
              </Col>
              <Col className='col-sm-10' style={{color:'var(--color-light)'}}>
              
                  {data.name}
                <small className='text-light'></small>
               
              
               
              </Col>


              </Row>

            </div>
          
          
        )
      }
      )}
    </div>
  );
};

