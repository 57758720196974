const apiKey = 'AIzaSyAVd4UyFzSxXExF9yIQ2DvbGjeS87Ynnqw';//basbhai2011 api:'AIzaSyAj5nrolDB8BqAyzt5BBR37Tx-cxDBco8s'; 
const channelId='UCuZqhjLxIVzBj4mDkvlM9zQ';      //'UCWeOMz6B427vD40SUS-894g';
const url = `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${channelId}&part=snippet,id&order=date&maxResults=5`;



export async function getYtVideos() {
    return await fetch(url)
      .then(data => data.json())
      .then(list => {
        const videoIds = list.items
          .filter(item => item.id && item.id.videoId) // Filter out items with undefined videoIds
          .map(item => item.id.videoId);
        console.log(videoIds);
        return videoIds;
      });
  }


/*

export async function getYtVideos() {
  try {
    const response = await fetch();
    const data = await response.json();
    const videoList = data.items;

    // Process the video list
    console.log(videoList);
    return videoList;
    // Perform any additional operations with the video list

  } catch (error) {
    console.error('Error fetching video list:', error);
  }
}
*/

