import React, { useState } from 'react';
import './Nav.css';
import { ImHome3 } from 'react-icons/im';
import { SiAboutdotme } from 'react-icons/si';
import { TfiGallery } from 'react-icons/tfi';
import { BiSolidBookBookmark, BiSolidContact } from 'react-icons/bi';

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#');

  return (
    <nav>
      <a href='#' onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}>
        <ImHome3 />
        <span className="icon-name">Home</span>
      </a>
      <a href='#about' onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}>
        <SiAboutdotme />
        <span className="icon-name">About</span>
      </a>
      <a href='#experience' onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}>
        <BiSolidBookBookmark />
        <span className="icon-name">Experience</span>
      </a>
      <a href='#portfolio' onClick={() => setActiveNav('#portfolio')} className={activeNav === '#portfolio' ? 'active' : ''}>
        <TfiGallery />
        <span className="icon-name">Portfolio</span>
      </a>
      <a href='#contact' onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}>
        <BiSolidContact />
        <span className="icon-name">Contact</span>
      </a>
    </nav>
  );
};

export default Nav;