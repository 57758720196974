import React, { useState, useEffect } from 'react';
import { getYtVideos } from '../ApiCalls/youtubeApi';
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import { Container,Row,Col } from 'react-bootstrap';


const VideoElement = (props) => {
  return (
    <Col className=''>
      <iframe
        width="450"
        height="280"
        src={`https://www.youtube.com/embed/${props.id}`}
      ></iframe>
    </Col>
  );
};

const VideoBlock = () => {
  const [videoData, setVideoData] = useState([]);

  useEffect(() => {
    getYtVideos()
      .then((data) => {
        setVideoData(data);
      })
      .catch((error) => {
        console.error('Error fetching YouTube videos:', error);
      });
  }, []);
/*
  const transformData = (data) => {
    return data.map((item) => {
      return item.videoId;
    });
  };*/

  return (
    <Container>
      <Row>

      {videoData.length ? (
        videoData.map((videoId, idx) => (
          <VideoElement key={idx} id={videoId} />
        ))
      ) : (
        []
      )}

      </Row>
      
    </Container>
  );
};

export default VideoBlock;