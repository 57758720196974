import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2> Contact Me</h2>

     <div className="container contact__container">
        <div className="contact__options">
        <article className='contact__option'>
          <h4>Email</h4>
        </article>
        </div>


     </div>





    </section>
  )
}

export default Contact