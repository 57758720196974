import React from 'react'
import CV from '../../assets/cv.pdf'

const CTA = () => {
  return (
<div className='cta'>
    <a href={CV} download className='btn '>Download Cv</a>
    <a href="#Contact" className='btn btn-primary '>Let's Talk</a>
</div>
  )
}

export default CTA