import React from 'react';
import './About.css';
import ME from '../../assets/me-about.jpg';
import {FaAward} from 'react-icons/fa';
import {VscOrganization} from 'react-icons/vsc';
import {MdOutlineWork} from 'react-icons/md';
//import AboutMe from './AboutMe';

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className='container about__container'>
      <div className="about__me">
        <div className="about__me-image">
          <img src={ME} alt="About Image" />
        </div>
      </div>
      <div className="about__content">
        <div className="about__cards">
          
          <article className='about__card'>
          <FaAward className='about__icon'/>
            <h5>Experiences</h5>
            <small>12+ of Banking Career</small>
          </article>
          <article className='about__card'>
          <VscOrganization className='about__icon'/>
            <h5>Organization & Departments</h5>
            <small></small>
          </article>
          <article className='about__card'>
          <MdOutlineWork className='about__icon'/>
            <h5>Projects</h5>
            <small></small>
          </article>
        </div>
        <p>
      Highly experienced banker with over a decade of expertise in credit management, 
      branch management, and MIS seeking a challenging position where I can leverage my strong analytical skills and financial 
      acumen. Additionally, as a versatile Javascript and Node.js developer, I bring a unique blend of technical proficiency and banking knowledge to deliver innovative solutions.
      </p>
      <a href='#contact' className='btn btn-primary '>Let's Talk</a>
      </div>
      


      </div>
  





    </section>
  )
}

export default About